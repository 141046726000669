.options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.option {
  width: 45%;
  border: rgba(0, 0, 0, 0.5) 1px solid;
  border-radius: 10px;
  margin: 5px 0;
  padding: 5px;
  background-color: #fdfdfd;
}

@media (max-width: 500px) {
  .options {
    flex-direction: column;
  }
  .option {
    width: 100%;
  }

  .bottom-stick {
    position: fixed;
    bottom: 0;
    left: 0;
    // padding: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    box-sizing: border-box;
  }
}

.active {
  background-color: #1a75ff1a !important;
  border: 1px solid #1a75ff !important;
}

.countdown {
  position: absolute;
  bottom: 10px;
  right: 30px;
}

.countdown-time {
  background-color: green;
  color: #fff;
  padding: 5px;
}