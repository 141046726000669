.btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

@media (max-width: 500px) {
  .btns {
    position: fixed;
    bottom: 10px;
    flex-direction: column;
    box-sizing: border-box;
  }
}

.warm-heading {
  background-color: rgb(189, 194, 128);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  
  p {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
  }
}