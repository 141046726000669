.circle-wrap {
  width: 132px;
  height: 132px;
  background: #FFA5B6;
  border-radius: 50%;
  margin: 10px;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 132px;
  height: 132px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  // clip: rect(0px, 150px, 150px, 66px);
}

.circle-wrap .inside-circle {
  width: 102px;
  height: 102px;
  border-radius: 50%;
  background: #FF90A3;
  line-height: 102px;
  text-align: center;
  margin-top: 14px;
  margin-left: 14px;
  color: #fff;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 1.5rem;
}

.mask .fill {
  clip: rect(0px, 66px, 150px, 0px);
  background-color: #fff;
}

@media (max-width: 600px) {
  .circle-wrap {
    width: 145px;
    height: 132px;
  }
  
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 132px;
    height: 132px;
  }
  
  .circle-wrap .inside-circle {
    width: 102px;
    height: 102px;
    line-height: 102px;
    font-size:1.4rem;

  }
}