//  BASIC
$PRIMARY: #1779BA;
$SECONDARY: #767676;
$WHITE: #FFFFFF;
$BLACK: #000000;
$ORANGE:#F07B48;

// BASE
$BASE_ORANGE: #FD7512;
$BASE_BLUE : #6F17FF;


$SUCCESS:#3ADB76;
$WARNING:#FFAE00;
$ALERT:#F84145;


// GRAYSCALE
$GRAY_LIGHT:#E6E6E6;
$GRAY_MEDIUM:#CACACA;
$GRAY_DARK:#4A4A4A;

