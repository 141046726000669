.signup-heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.heading {
  margin-left: 1rem;
}
.signup-btn{
  width: 16rem !important;
  margin-bottom: 1rem;
  margin-top: 1rem;

}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form-control {
    text-align: start !important;
    background: transparent;
  }
}