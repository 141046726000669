.signup-heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.heading {
  margin-left: 1rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form-control {
    text-align: center;
    background: transparent;
  }
}


.profile-box{
  box-shadow: 1px -2px 8px 0px rgba(230, 222, 222, 0.56);
  -webkit-box-shadow: 1px -2px 8px 0px rgba(187, 183, 183, 0.56);
  -moz-box-shadow: 1px -2px 8px 0px rgba(215, 203, 203, 0.56);
  width: 30rem;
  padding-top: 3rem;
  margin-top: 3rem;
  border-radius: 3rem;

}

@media screen and (max-width: 39.9375em) {
.profile-box{
  margin-top: 1rem;
  box-shadow: none;
  width: inherit;
  border-radius: 0rem !important;

}

}

