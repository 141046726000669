//flexbox
.flex {
    &-jc-sb {
      justify-content: space-between;
    }
    &-jc-c {
      justify-content: center;
    }
    &-jc-se {
      justify-content: space-evenly;
    }
    &-ai-c {
      align-items: center;
    }
    &-dc {
      flex-direction: column;
    }
    &-jc-l {
      justify-content: left;

    }
  }
  
.cursor{
  cursor: pointer;
}


.hide-for-mobile{
  @include breakpoint-down(medium){
    display: none !important;
  }
}


.hide-for-desktop{
  @include breakpoint-up(large){
    display: none !important;
  }
}

.pricing-card{
  @include breakpoint-up(large){
    // height: 600px !important
  
  }
}
