.quiz-url-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quiz-url-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  background-color: #2ba7dc;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 10px;
  width: 100%;
  box-shadow: black 5px 5px 14px -2px;

  input {
    width: 80%;
    border-radius: 20px;
  }

  input:focus::placeholder {
    color: transparent;
  }

  .head {
    background-color: #fff;
    width: 90%;
    border-radius: 20px;
    padding: 5px;
    text-align: center;
    font-weight: 200;
    font-size: 24px;
    margin: 10px 0;
  }

  button {
    width: 20%;
    border-radius: 100%;
    width: max-content;
    height: max-content;
    margin: 10px;
  }
}
