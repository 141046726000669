
$BES_PURPLE: #2ba7dc;

.login-page{
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 
}
.login-container{
   h1,span{
    text-align: center;
  }
  a,i{
    color: #2ba7dc !important; 
  }
}
.login-btn{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 45px !important;

}


.google-login{
  width: inherit !important;
  height: 53px !important;
  background-color: #ffff !important;
  color: $BES_PURPLE !important;
  border-radius: 0.5rem;
  box-shadow: none !important;
  font-weight: 500;
  border: 1px  solid $BES_PURPLE !important;
  border-color: $BES_PURPLE !important;
  border-radius: 5px !important;

  span{
    margin-right: 3.4rem;

  }

  &:hover{
    background-color: $BES_PURPLE !important;
    span{
    color: white !important;

    }
  }
}


.login-container .btn{ 
  width: 100%;
}

.form-label {
  text-align: start;
}

.signup-container {
  background-color: #1975ff;
  color: #fff;
  padding: 40px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: #ffffff73;
    margin: 5px;
  }
}

.login-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn-light {
  color: #1a75ff !important;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }
  .login-container {
    width: 100%;
    height: 50%;
  }
  .signup-container {
    width: 100%;
    height: 50%;
  }
}
