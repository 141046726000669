.timing {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px;
  padding: 15px 45px;
}

.min-sec {
  // font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 10px;
  h2 {
    margin: 0;
    font-size: 20px;
  }
  span {
    color: #fff;
    font-size: 10px;
  }
}

.status {
  color: #1975ff;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  width: 100%;

  .body-tag {
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      word-wrap: break-word;
      width: 50%;
      margin: 10px;
      color: #fff;
      font-size: 18px;
    }
  }

  .bottom-tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #feb381;
    border-radius: 20px;
    padding: 10px;

    .c-icon {
      background-color: #ff9b52;
      border-radius: 20px;
      padding: 10px;
      margin: 5px;
      color: #fff;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 90%;

      h3 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
      }
      p {
        font-size: 10px;
        font-style: italic;
        margin-bottom: 0;
      }
    }
  }
}

.warning-card {
  background-color: #f1dfd5;
}

.pink-card {
  background-color: #ff90a3;
}

.view-answers {
  width: 100%;

  .btn-answer {
    float: right;
    background-color: #19a3dc;
    border-radius: 10px;
    padding: 5px;
    margin: 5px 0;
    color: #fff;
    cursor: pointer;
  }
}

.answer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  padding-bottom: 10px;

  .answer-icon {
    padding-right: 10px;
    padding-top: 10px;
  }

  p {
    margin: 0;
  }
}

.img_mobile{
  margin-top: 1rem;
  width: 100%;
  height: 10rem;
  object-fit:contain;
  margin-bottom: 1rem;


}

@media screen and (max-width: 39.9375em) {
  .img_mobile{
    margin-top: 0.5rem;
    width: 100%;
    height: 10rem;
    object-fit: contain;
    margin-bottom: 0.5rem;

  
  }
}